import React from 'react'
import { Link } from 'react-router-dom';

/* Styles */
var islandBackground = {
  backgroundImage: `img/island.png`  
};
var patternBackground = {
  backgroundImage: `img/patterns/ep_naturalblack.png`  
};
var girlOnPhoneBackground= {
  backgroundImage: `img/1500x800.png`  
};

export default function Home() {
  return (
    <div className="_pos-r">
      <div className="theme-hero-area _h-desk-100vh">
        <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg-video theme-hero-area-bg-video-blur" id="youtube-video" data-video-id="R4MK9nkHtHM"></div>
          <div className="theme-hero-area-bg" style={ islandBackground }></div>
          <div className="theme-hero-area-bg-pattern theme-hero-area-bg-pattern-strong" style={ patternBackground}></div>
          <div className="theme-hero-area-mask"></div>
          <div className="theme-hero-area-inner-shadow"></div>
        </div>
        <div className="theme-hero-area-body _pos-desk-v-c _w-f _pv-mob-50">
          <div className="container">
            <div className="theme-search-area-tabs _pb-100">
              <div className="theme-search-area-tabs-header _c-w _mb-50">
                <h1 className="theme-search-area-tabs-title theme-search-area-tabs-title-lg">Get Up There</h1>
                <p className="theme-search-area-tabs-subtitle">Search and book flights to thousands of destinations worldwide</p>
              </div>
              <div className="tabbable">
                <ul className="nav _fw-b _mb-5 nav-tabs nav-white nav-blank nav-xl nav-active-primary nav-mob-inline" role="tablist">
                  <li role="active">
                    <a aria-controls="SearchAreaTabs-3" role="tab" data-toggle="tab" href="#SearchAreaTabs-3"></a>
                  </li>
                </ul>
                <div className="tab-content _pt-15">
                  <div className="tab-pane active" id="SearchAreaTabs-3" role="tab-panel">
                    <div className="theme-search-area theme-search-area-white">
                      <div className="theme-search-area-form">
                        <div className="row" data-gutter="30">
                          <div className="col-md-5 ">
                            <div className="row" data-gutter="30">
                              <div className="col-md-6 ">
                                <div className="theme-search-area-section first theme-search-area-section-line">
                                  <div className="theme-search-area-section-inner">
                                    <i className="theme-search-area-section-icon lin lin-location-pin"></i>
                                    <input className="theme-search-area-section-input typeahead" type="text" placeholder="Departure" data-provide="typeahead" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 ">
                                <div className="theme-search-area-section theme-search-area-section-line">
                                  <div className="theme-search-area-section-inner">
                                    <i className="theme-search-area-section-icon lin lin-location-pin"></i>
                                    <input className="theme-search-area-section-input typeahead" type="text" placeholder="Arrival" data-provide="typeahead" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 ">
                            <div className="row" data-gutter="30">
                              <div className="col-md-4 ">
                                <div className="theme-search-area-section theme-search-area-section-line">
                                  <div className="theme-search-area-section-inner">
                                    <i className="theme-search-area-section-icon lin lin-calendar"></i>
                                    <input className="theme-search-area-section-input datePickerStart _mob-h" value="Wed 06/27" type="text" placeholder="Check-in" />
                                    <input className="theme-search-area-section-input _desk-h mobile-picker" value="2018-06-27" type="date" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 ">
                                <div className="theme-search-area-section theme-search-area-section-line">
                                  <div className="theme-search-area-section-inner">
                                    <i className="theme-search-area-section-icon lin lin-calendar"></i>
                                    <input className="theme-search-area-section-input datePickerEnd _mob-h" value="Mon 07/02" type="text" placeholder="Check-out" />
                                    <input className="theme-search-area-section-input _desk-h mobile-picker" value="2018-07-02" type="date" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 ">
                                <div className="theme-search-area-section theme-search-area-section-line quantity-selector" data-increment="Passengers">
                                  <div className="theme-search-area-section-inner">
                                    <i className="theme-search-area-section-icon lin lin-people"></i>
                                    <input className="theme-search-area-section-input" value="1 Passenger" type="text" />
                                    <div className="quantity-selector-box" id="FlySearchPassengers">
                                      <div className="quantity-selector-inner">
                                        <p className="quantity-selector-title">Passengers</p>
                                        <ul className="quantity-selector-controls">
                                          <li className="quantity-selector-decrement">
                                            <a href="#">&#45;</a>
                                          </li>
                                          <li className="quantity-selector-current">1</li>
                                          <li className="quantity-selector-increment">
                                            <a href="#">&#43;</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 ">
                            <button className="theme-search-area-submit _mt-0 _fs-xl theme-search-area-submit-curved theme-search-area-submit-primary theme-search-area-submit-glow">&rarr;</button>
                          </div>
                        </div>
                      </div>
                      <div className="theme-search-area-options clearfix">
                        <div className="btn-group theme-search-area-options-list" data-toggle="buttons">
                          <label className="btn btn-primary active">
                            <input type="radio" name="flight-options" id="flight-option-1" checked />Round Trip
                        </label>
                          <label className="btn btn-primary">
                            <input type="radio" name="flight-options" id="flight-option-2" />One Way
                        </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme-hero-area">
        <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg" style={ girlOnPhoneBackground }></div>
          <div className="theme-hero-area-inner-shadow theme-hero-area-inner-shadow-light"></div>
        </div>
        <div className="theme-hero-area-body">
          <div className="container">
            <div className="theme-page-section _p-0">
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <div className="theme-mobile-app">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="theme-mobile-app-section">
                          <div className="theme-mobile-app-body">
                            <div className="theme-mobile-app-header">
                              <h2 className="theme-mobile-app-title">Download our app</h2>
                              <p className="theme-mobile-app-subtitle">Book and manage your trips on the go. Be notified of our hot deals and offers.</p>
                            </div>
                            <ul className="theme-mobile-app-btn-list">
                              <li>
                                <a className="btn btn-dark theme-mobile-app-btn" href="#">
                                  <i className="theme-mobile-app-logo">
                                    <img src="img/brands/apple.png" alt="Image Alternative text" title="Image Title" />
                                  </i>
                                  <span>Download on
                                  <br />
                                    <span>App Store</span>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a className="btn btn-dark theme-mobile-app-btn" href="#">
                                  <i className="theme-mobile-app-logo">
                                    <img src="img/brands/play-market.png" alt="Image Alternative text" title="Image Title" />
                                  </i>
                                  <span>Download on
                                  <br />
                                    <span>Google Play</span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="theme-mobile-app-section"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// index-3.html