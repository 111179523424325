import React from 'react'

export default function FlightResults() {
  return (
    <div>
      <h1> FlightResults </h1>
    </div>
  )
}


// flight-results-3.html