import React from 'react'

export default function AccountNotifications() {
  return (
    <div>
      <h1> Account Notifications</h1>
    </div>
  )
}


// account-notifications.html