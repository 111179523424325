import React from 'react'

export default function AboutUs() {
  return (
    <div>
      <h1> About Us</h1>
    </div>
  )
}

// about-us.html