import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogOut from './LogOut';

/*TODO: Go through and update all image alt tags to have proper description, per lint warning, do not include the word "Image" */

class Navbar extends Component {
  render() {
    return (
        <nav className="navbar navbar-default navbar-inverse navbar-theme navbar-theme-abs navbar-theme-transparent navbar-theme-border" id="main-nav">
        <div className="container">
          <div className="navbar-inner nav">
            <div className="navbar-header">
              <button className="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button" area-expanded="false">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link className="navbar-brand" to="/">
                <img src="img/logo.png" alt="FareUpThere Logo" title="FareUpThere Logo"/>
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="navbar-main">
              <ul className="nav navbar-nav navbar-right">
                <li className="dropdown">
                  <Link className="dropdown-toggle" to="/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <span className="_desk-h">Currency</span>
                    <b>USD</b>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-xxl">
                    <h5 className="dropdown-meganav-select-list-title">Popular Currencies</h5>
                    <div className="row" data-gutter="10">
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                            <Link to="/">
                              <span>€</span>Euro
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>£</span>Pound sterling
                            </Link> 
                          </li>
                          <li className="active">
                          <Link to="/">
                              <span>US$</span>U.S. dollar
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                            <Link to="/">
                              <span>CAD</span>Canadian dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>AUD</span>Australian dollar
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>RUB</span>Russian Ruble
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                          <Link to="/">
                              <span>S$</span>Singapore dollar
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>CNY</span>Chinese yuan
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>¥</span>Japanese yen
                            </Link> 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr/>
                    <h5 className="dropdown-meganav-select-list-title">All Currencies</h5>
                    <div className="row" data-gutter="10">
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                          <Link to="/">
                              <span>AR$</span>Argentine peso
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>AUD</span>Australian dollar
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>AZN</span>Azerbaijan, New Ma...
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>BHD</span>Bahrain dinar
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>BRL</span>Brazilian real
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>BGN</span>Bulgarian lev
                            </Link> 
                          </li>
                          <li>
                          <Link to="/">
                              <span>CAD</span>Canadian dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>XOF</span>CFA Franc BCEAO
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>CL$</span>Chilean peso
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>CNY</span>Chinese yuan
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>COP</span>Colombian peso
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>Kč</span>Czech koruna
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>DKK</span>Danish krone
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                            <Link to="/">
                              <span>EGP</span>Egyptian pound
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>€</span>Euro
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>FJD</span>Fijian dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>GEL</span>Georgian lari
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>HK$</span>Hong Kong Dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>HUF</span>Hungarian forint
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>Rs.</span>Indian rupee
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>Rp</span>Indonesian rupiah
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>₪</span>Israeli new sheqel
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>¥</span>Japanese yen
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>JOD</span>Jordanian dinar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>KZT</span>Kazakhstani tenge
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>KRW</span>Korean won
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                            <Link to="/">
                              <span>KWD</span>Kuwaiti dinar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>MYR</span>Malaysian ringgit
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>MXN</span>Mexican peso
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>MDL</span>Moldovan leu
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>NAD</span>Namibian Dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>TWD</span>New Taiwan Dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>NZD</span>New Zealand dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>NOK</span>Norwegian krone
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>OMR</span>Omani rial
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>zł</span>Polish zloty
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>£</span>Pound sterling
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>QAR</span>Qatar riyal
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>lei</span>Romanian new leu
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-currency">
                          <li>
                            <Link to="/">
                              <span>RUB</span>Russian Ruble
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>SAR</span>Saudi Arabian riyal
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>S$</span>Singapore dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>ZAR</span>South African rand
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>SEK</span>Swedish krona
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>CHF</span>Swiss franc
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>THB</span>Thai baht
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>TL</span>Turkish lira
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>AED</span>U.A.E. dirham
                            </Link> 
                          </li>
                          <li className="active">
                            <Link to="/">
                              <span>US$</span>U.S. dollar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>UAH</span>Ukraine Hryvnia
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <span>UZS</span>Uzbekistan, Sums
                            </Link> 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="dropdown">
                  <Link className="dropdown-toggle" to="/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <span className="_desk-h">Language</span>
                    <img className="navbar-flag" src="img/flags/USA.png" alt="Default language flag" title="Default languag flag"/>
                  </Link> 
                  <div className="dropdown-menu dropdown-menu-xxl">
                    <h5 className="dropdown-meganav-select-list-title">Languages</h5>
                    <div className="row" data-gutter="10">
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-lang">
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/UK.png" alt="UK flag" title="UK Flag"/>English(UK)
                            </Link> 
                          </li>
                          <li className="active">
                            <Link to="/">
                              <img src="img/flag_codes/US.png" alt="USA flag" title="USA flag"/>English(US)
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/DE.png" alt="Germany flag" title="Germany flag"/>Deutsch
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/NED.png" alt="Netherlands flag" title="Netherlands flag"/>Nederlands
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/FR.png" alt="France Flag" title="France flag"/>Français
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/SP.png" alt="Spain flag" title="Spain flag"/>Español
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/ARG.png" alt="Argentina flag" title="Argentina flag"/>Español (AR)
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/IT.png" alt="Italy flag" title="Italy flag"/>Italiano
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/PT.png" alt="Portugal flag" title="Portugal flag"/>Português (PT)
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/BR.png" alt="Brazil flag" title="Brazil flag"/>Português (BR)
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/NR.png" alt="Norway flag" title="Norway flag"/>Norsk
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-lang">
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/FIN.png" alt="Finland flag" title="Finland flag"/>Suomi
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/SW.png" alt="Sweden flag" title="Sweden flag"/>Svenska
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/DEN.png" alt="Denmark flag" title="Denmark flag"/>Dansk
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/CZ.png" alt="Czechia flag" title="Czechia flag"/>Čeština
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/HUN.png" alt="Hungary flag" title="Hungary flag"/>Magyar
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/ROM.png" alt="Romania flag" title="Romania flag"/>Română
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/JP.png" alt="Japan flag" title="Japan flag"/>日本語
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/CN.png" alt="China Flag" title="China flag"/>简体中文
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/PL.png" alt="Poland flag" title="Poland flag"/>Polski
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/GR.png" alt="Greece flag" title="Greece flag"/>Ελληνικά
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/RU.png" alt="Russia flag" title="Russia flag"/>Русский
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-lang">
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/TUR.png" alt="Turkey flag" title="Turkey flag"/>Türkçe
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/BUL.png" alt="Bulgaria flag" title="Bulgaria flag"/>Български
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/ARB.png" alt="Arab League flag" title="Arab League flag"/>العربية
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/KOR.png" alt="South Korea flag" title="South Korea"/>한국어
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/ISR.png" alt="Israel flag" title="Israel flag"/>עברית
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/LAT.png" alt="Lativa flag" title="Lativa flag"/>Latviski
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/UKR.png" alt="Ukraine flag" title="Ukraine flag"/>Українська
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/IND.png" alt="Indonesia flag" title="Indonesia flagImage Title"/>Bahasa Indonesia
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/MAL.png" alt="Malaysia flag" title="Malaysia flag"/>Bahasa Malaysia
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/TAI.png" alt="Thailand flag" title="Thailand flag"/>ภาษาไทย
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/EST.png" alt="Estonia flag" title="Estonia flag"/>Eesti
                            </Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <ul className="dropdown-meganav-select-list-lang">
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/CRO.png" alt="Croatia flag" title="Croatia flag"/>Hrvatski
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/LIT.png" alt="Lithuania flag" title="Lithuania flag"/>Lietuvių
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/SLO.png" alt="Slovakia flag" title="Slovakia flag"/>Slovenčina
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/SERB.png" alt="Serbia flag" title="Serbia flag"/>Srpski
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/SLOVE.png" alt="Slovenia flag" title="Slovenia flag"/>Slovenščina
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/NAM.png" alt="Vietnam flag" title="Vietnam flag"/>Tiếng Việt
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/PHI.png" alt="Philippines flag" title="Philippines flag"/>Filipino
                            </Link> 
                          </li>
                          <li>
                            <Link to="/">
                              <img src="img/flag_codes/ICE.png" alt="Iceland flag" title="Iceland flag"/>Íslenska
                            </Link> 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="navbar-nav-item-user dropdown">
                  <Link className="dropdown-toggle" to="/account" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-user-circle-o navbar-nav-item-user-icon"></i>My Account
                  </Link> 
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/account">Preferences</Link> 
                    </li>
                    <li>
                      <Link to="/accountNotifications">Notifications</Link> 
                    </li>
                    <li>
                      <Link to="/accountCards">Payment Methods</Link> 
                    </li>
                    <li>
                      <Link to="/accountTravelers">Travelers</Link> 
                    </li>
                    <li>
                      <Link to="/accountHistory">History</Link> 
                    </li>
                    <LogOut />
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar;

// new componenet: rcc enter
// new functional componenet: rfc enter