import React from 'react'

export default function Account() {
  return (
    <div>
      <h1> Account</h1>
    </div>
  )
}


// account.html