import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import NavBar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import Account from './components/pages/Account';
import AccountCards from './components/pages/AccountCards';
import AccountHistory from './components/pages/AccountHistory';
import AccountNotifications from './components/pages/AccountNotifications';
import AccountTravelers from './components/pages/AccountTravelers';
import Contact from './components/pages/Contact';
import FlightResults from './components/pages/FlightResults';
import GuestPayment from './components/pages/GuestPayment';
import Loading from './components/pages/Loading';
import Login from './components/pages/Login';
import PasswordReset from './components/pages/PasswordReset';
import Payment from './components/pages/Payment';
import PaymentSuccess from './components/pages/PaymentSuccess';
import Register from './components/pages/Register';
import NotFound from './components/pages/NotFound';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        {/* OPTIONAL: <div className="container"> */}
          <Route path="/" exact={true} component={Home} />
          <Route path="/aboutUs" exact={true} component={AboutUs} />
          <Route path="/register" exact={true} component={Register} />
          <Route path="/login" exact={true} component={Login} />
          <Route path="/account" exact={true} component={Account} />
          <Route path="/accountCards" exact={true} component={AccountCards} />
          <Route path="/accountHistory" exact={true} component={AccountHistory} />
          <Route path="/accountNotifications" exact={true} component={AccountNotifications} />
          <Route path="/accountTravelers" exact={true} component={AccountTravelers} />
          <Route path="/contact" exact={true} component={Contact} />
          <Route path="/flightResults" exact={true} component={FlightResults} />
          <Route path="/guestPayment" exact={true} component={GuestPayment} />
          <Route path="/loading" exact={true} component={Loading} />
          <Route path="/notFound" exact={true} component={NotFound} />
          <Route path="/passwordReset" exact={true} component={PasswordReset} />
          <Route path="/payment" exact={true} component={Payment} />
          <Route path="/paymentSuccess" exact={true} component={PaymentSuccess} />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
