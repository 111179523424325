import React from 'react'

export default function GuestPayment() {
  return (
    <div>
      <h1> Guest Payment </h1>
    </div>
  )
}


// flight-payment-1.html