import React from 'react'

export default function Payment() {
  return (
    <div>
      <h1> Payment </h1>
    </div>
  )
}

// flight-payment-2.html