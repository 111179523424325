import React from 'react'

export default function Contact() {
  return (
    <div>
      <h1> Contact </h1>
    </div>
  )
}


// contact.html