import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner'

import { withFirebase } from '../firebase';

var centerContent = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

// Dialogs are appearing too close to the form
var alertStyle = {
  marginTop: 16
};

class LoginPage extends Component {

  render() {
    const history = this.props.history;
    return (<div>
      <div style={navbarPadding}> </div>
      <div className="theme-page-section theme-page-section-xl theme-page-section-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <div className="theme-login">
                <div className="theme-login-header">
                  <h1 className="theme-login-title">Log In</h1>
                  <p className="theme-login-subtitle">Login into your FareUpThere account</p>
                </div>
                <div className="theme-login-box">
                  <div className="theme-login-box-inner">
                    <Login history={history} />
                  </div>
                  <div className="theme-login-box-alt">
                    <p>Don't have an account? &nbsp;
                <Link to="/register">Sign up.</Link>
                    </p>
                  </div>
                </div>
                <p className="theme-login-terms">By logging in you accept our
            <Link to="/"> terms of use </Link>
                  <br />and
            <Link to="/"> privacy policy</Link>.
          </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
}

/* Styles */
var navbarPadding = {
  height: 50,
  backgroundColor: '#333'
};

class LoginBase extends Component {
  constructor(props) {
    super(props);

    var newAccountValue = false;
    if (this.props.history.location.state) {
      newAccountValue = this.props.history.location.state.newAccount
    }

    this.state = {
      email: '',
      password: '',
      error: null,
      success: null,
      loading: null,
      newAccount: newAccountValue
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ loading: true });
    this.setState({ newAccount: false });
    this.setState({ error: false });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({
          email: this.email,
          password: this.password,
          error: null,
          success: true,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          error: error.message,
          success: false,
          loading: false
        })
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      email,
      password,
      error,
      success,
      loading,
      newAccount
    } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form className="theme-login-form" onSubmit={this.onSubmit}>
        <div className="form-group theme-login-form-group">
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            className="form-control"
            type="text"
            placeholder="Email Address" />
        </div>
        <div className="form-group theme-login-form-group">
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            className="form-control"
            type="password"
            placeholder="Password" />
          <p className="help-block">
            <Link to="/passwordReset">Forgot password?</Link>
          </p>
        </div>
        <div className="form-group">
          <div className="theme-login-checkbox">
            <label className="icheck-label">
              <input className="icheck" type="checkbox" />
              <span className="icheck-title">Keep me logged in</span>
            </label>
          </div>
        </div>
        {success ? (
          <Redirect to='/'></Redirect>
        ) : ""}
        {loading ? (
          <div style={centerContent}>
            <Loader type="TailSpin" color="#00BFFF" height={50} width={50} />
          </div>
        ) : <button disabled={isInvalid} type="submit" className="btn btn-uc btn-dark btn-block btn-lg">Log In</button>
        }
        {error ? (
          <div className="alert alert-danger alert-dismissible" data-dismiss="alert" style={alertStyle} role="alert">
            {error}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : ""}
        {newAccount ? (
          <div className="alert alert-success alert-dismissible" data-dismiss="alert" style={alertStyle} role="alert">
            Account created, sign in
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : ""}
      </form>
    )
  }
}

const Login = withFirebase(LoginBase);

export default LoginPage;
export { Login }

// login.html