import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner'

/* Styles */
var pageBackground = {
  backgroundImage: `img/1500x800.png`
};

var centerContent = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function Loading() {
  return (
    <div>
      <div className="theme-hero-area theme-hero-area-full">
        <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg" style={pageBackground}></div>
          <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
          <div className="theme-hero-area-inner-shadow"></div>
        </div>
        <div className="theme-hero-area-body theme-hero-area-body-vert-center">
          <div className="container">
            <div className="theme-loading">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <div className="spinner theme-loading-spinner">
                    <div style={centerContent}>         
                      <Loader type="Plane" color="#00BFFF" height={50} width={50} />
                    </div>
                  </div>
                  <div className="theme-loading-body">
                    <h2 className="theme-loading-title">Just a moment</h2>
                    <p className="theme-loading-subtitle">We are looking for flights in
                    <br />
                      <b>New York, USA</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    </div>
  )
}

// Loading.js