import React from 'react'

export default function AccountHistory() {
  return (
    <div>
      <h1> Account History </h1>
    </div>
  )
}


// account-history.html