import React from 'react'

export default function PaymentSuccess() {
  return (
    <div>
      <h1> Payment Success </h1>
    </div>
  )
}


// payment-success.html