import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/* Styles */
var subscribeStyle = {
    backgroundImage: `img/footer/footer_subscribe_bg.png`  
};


class Footer extends Component {
    render() {
        return (
            <div>
            <div className="theme-footer" id="mainFooter">
            <div className="container _ph-mob-0">
              <div className="row row-eq-height row-mob-full" data-gutter="60">
                <div className="col-md-3">
                  <div className="theme-footer-section theme-footer-">
                    <Link className="theme-footer-brand _mb-mob-30" to="/">
                      <img src="img/logo-black.png" alt="FareUpThere logo" title="FareUpThere logo"/>
                    </Link>
                    <div className="theme-footer-brand-text">
                      <p>Eget est blandit pulvinar morbi ligula vel dignissim inceptos dignissim eleifend tortor tempus dictumst tincidunt</p>
                      <p>Neque eu integer venenatis fames ultricies pretium mauris maecenas interdum pharetra sodales congue a felis</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="theme-footer-section theme-footer-">
                        <h5 className="theme-footer-section-title">Important Stuff</h5>
                        <ul className="theme-footer-section-list">
                          <li>
                            <Link to="/aboutUs">About FareUpThere</Link>
                          </li>
                          <li>
                            <Link to="/">Mobile App</Link>
                          </li>
                          <li>
                            <Link to="/">Customer Support</Link>
                          </li>
                          <li>
                            <Link to="/">Advertising</Link>
                          </li>
                          <li>
                            <Link to="/">Jobs</Link>
                          </li>
                          <li>
                            <Link to="/">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/">Terms of Use</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="theme-footer-section theme-footer-">
                        <h5 className="theme-footer-section-title">Explore</h5>
                        <ul className="theme-footer-section-list">
                          <li>
                            <Link to="/">Countries</Link>
                          </li>
                          <li>
                            <Link to="/">Regions</Link>
                          </li>
                          <li>
                            <Link to="/">Cities</Link>
                          </li>
                          <li>
                            <Link to="/">Districs</Link>
                          </li>
                          <li>
                            <Link to="/">Airports</Link>
                          </li>
                          <li>
                            <Link to="/">Hotels</Link>
                          </li>
                          <li>
                            <Link to="/">Places of Interest</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="theme-footer-section theme-footer-section-subscribe bg-grad _mt-mob-30">
                    <div className="theme-footer-section-subscribe-bg"  style={ subscribeStyle }></div>
                    <div className="theme-footer-section-subscribe-content">
                      <h5 className="theme-footer-section-title">Save up to 50% off your next flight</h5>
                      <p className="text-muted">Subscribe to unlock our secret deals</p>
                      <form>
                        <div className="form-group">
                          <input className="form-control theme-footer-subscribe-form-control" type="email" placeholder="Type your e-mail here"/>
                        </div>
                        <button className="btn btn-primary-invert btn-shadow text-upcase theme-footer-subscribe-btn" type="submit">Get deals</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="theme-copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p className="theme-copyright-text">Copyright &copy; 2019
                    <Link to="/"> FareUpThere Inc. </Link>. All rights reserved.
                  </p>
                </div>
                <div className="col-md-6">
                  <ul className="theme-copyright-social">
                    <li>
                      <a className="fa fa-facebook" href="https://www.facebook.com/fareupthere"></a>
                    </li>
                    <li>
                    <a className="fa fa-twitter" href="https://twitter.com/fareupthere"></a>
                    </li>
                    <li>
                    <a className="fa fa-instagram" href="https://www.instagram.com/fareupthere/"></a>
                    </li>
                    <li>
                    <a className="fa fa-snapchat" href="https://www.snapchat.com/add/fareupthere"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> 
          </div>
        )
    }
}

export default Footer;