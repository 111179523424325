import React from 'react'

export default function PasswordReset() {
  return (
    <div>
      <h1> PasswordReset </h1>
    </div>
  )
}


// pwd-reset.html