import React from 'react'

export default function NotFound() {
  return (
    <div>
      <h1> NotFound </h1>
    </div>
  )
}


// 404.html