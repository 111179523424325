import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner'

import { withFirebase } from '../firebase';

var centerContent = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

// Dialogs are appearing too close to the form
var alertStyle = {
  marginTop: 16
};

const RegisterPage = () => (
  <div>
    <div style={navbarPadding}> </div>
    <div className="theme-page-section theme-page-section-xl theme-page-section-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            <div className="theme-login">
              <div className="theme-login-header">
                <h1 className="theme-login-title">Sign Up</h1>
                <p className="theme-login-subtitle">Create new FareUpThere account</p>
              </div>
              <div className="theme-login-box">
                <div className="theme-login-box-inner">
                  <Register />
                </div>
                <div className="theme-login-box-alt">
                  <p>Already have an account? &nbsp;
              <Link to="/login">Log in.</Link>
                  </p>
                </div>
              </div>
              <p className="theme-login-terms">By signing up you accept our
          <a href="#"> terms of use </a>
                <br />and
          <a href="#"> privacy policy</a>.
        </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

/* Styles */
var navbarPadding = {
  height: 50,
  backgroundColor: '#333'
};

class RegisterBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      error: null,
      success: null,
      loading: null
    }
  }

  onSubmit = event => {
    const { email, password, loading } = this.state;
    this.setState({ loading: true });
    this.setState({ error: false });

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        this.setState({
          email: authUser.email,
          password: authUser.password,
          confirmPassword: authUser.confirmPassword,
          error: null,
          success: true,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          error: error.message,
          success: false,
          loading: false
        })
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      email,
      password,
      confirmPassword,
      error,
      success,
      loading
    } = this.state;

    const isInvalid =
      password !== confirmPassword ||
      password === '' ||
      email === '';

    const passwordsDontMatch = 
    (password !== confirmPassword) 
    && (confirmPassword !== "") 
    && (confirmPassword.length > 5 ) 
    && (password.length > 5)
    && (email.length > 8)
    && (email.includes("@"))
    && (email.includes("."));

    return (
      <form className="theme-login-form" onSubmit={this.onSubmit}>
        <div className="form-group theme-login-form-group">
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            className="form-control"
            type="txtEmail"
            placeholder="Email Address"
            required />
        </div>
        <div className="form-group theme-login-form-group">
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            className="form-control"
            type="password"
            placeholder="Create Password"
            required />
        </div>
        <div className="form-group theme-login-form-group">
          <input
            name="confirmPassword"
            value={confirmPassword}
            onChange={this.onChange}
            className="form-control"
            type="password"
            placeholder="Confirm Password"
            required />
        </div>
        {success ? (
          <Redirect to={{
            pathname: '/login',
            state: { newAccount: true }
          }}
          />
        ) : ""}
        {passwordsDontMatch ? (
          <div className="alert alert-danger alert-dismissible" data-dismiss="alert" style={alertStyle} role="alert">
            Passwords don't match.
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) :
          ""
        }
        {loading ? (
          <div style={centerContent}>
            <Loader type="TailSpin" color="#00BFFF" height={50} width={50} />
          </div>
        ) : <button disabled={isInvalid} type="submit" className="btn btn-uc btn-dark btn-block btn-lg">Create Account</button>
        }
        {error ? (
          <div className="alert alert-danger alert-dismissible" data-dismiss="alert" style={alertStyle} role="alert">
            {error}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : ""}
      </form>
    )
  }
}

const Register = withFirebase(RegisterBase);

export default RegisterPage;
export { Register }

// register.html